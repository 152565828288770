import Styled from "styled-components";
import { Link } from "gatsby";

const Button = Styled(Link)`
    border-radius: 4px;
    text-decoration: none;
    background: ${props => props.theme.headerText};
    border: 1px solid ${props => props.theme.headerText};
    color: ${props => props.theme.primary};
    font-weight: bold;
    font-size: 1.5rem;
    min-height: 3.25rem;
    padding: 0.65rem 1.25rem;
`;

export default Button;