import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import Styled from "styled-components";
import Button from "../components/button"

const Container = Styled.div`
  margin-top: 60px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h1 {
    text-align: center;
  }
  
  @media all and (min-width: 992px) {
    height: 100vh;
    margin-top: 0;
    padding: 50px 75px;
  }
`;

const title = "Thanks"

const Thanks = () => {
  return (
    <Layout activeMenu={title}>
      <SEO title={title} />
      <Container>
        <h1>Thank you for contacting us! We will get back to you soon.</h1>
        <Button to={"/"}>
          Go to home page
        </Button>
      </Container>
    </Layout>
  )
}

export default Thanks
